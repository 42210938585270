import { MdCreditCardOff, MdPayment } from 'react-icons/md';
import { useAuth } from '../context/AuthContext';
import { getChangedLabel } from '../utils/helperFunctions';

const ActivatePermitModal = ({ isOpen, onClose, onConfirm, onNoPayment }) => {
  const { organiztinType } = useAuth();
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-[rgba(0,0,0,0.5)] bg-opacity-75"
      onClick={onClose}
    >
      <div
        onClick={e => e.stopPropagation()}
        className="bg-white rounded-md shadow-lg p-9 w-[651px]"
      >
        <h2 className="text-lg font-bold text-center mb-9">
          Activate {getChangedLabel(organiztinType, true)}?
        </h2>
        <p className="text-sm text-[rgba(0,0,0,0.5)] text-left">
          If the customer is paying for the {getChangedLabel(organiztinType)}{' '}
          with a <strong>credit card</strong>, press “Take Payment” - this will
          redirect you to the checkout screen to complete the transaction.
        </p>
        <p className="text-sm text-[rgba(0,0,0,0.5)] text-left mt-2">
          If the customer is using any <strong>other form of payment</strong>{' '}
          outside Dashboard (e.g. check, cash, money order etc), press “No
          Payment”, and the {getChangedLabel(organiztinType)} will be activated
          immediately.
        </p>
        <div className="h-[1px] bg-[rgba(0,0,0,0.1)] my-9" />
        <div className="flex justify-center space-x-4 ">
          <button
            onClick={onClose}
            className="text-sm w-full py-2.5 bg-[rgba(0,0,0,0.05)] text-gray-800 font-semibold rounded-full hover:bg-[rgba(0,0,0,0.15)]"
          >
            Cancel
          </button>
          <button
            onClick={onNoPayment}
            className="text-sm w-full px-9 py-2.5 bg-[rgba(0,0,0,0.05)] text-gray-800 font-semibold rounded-full hover:bg-[rgba(0,0,0,0.15)]"
          >
            <span className="mr-2 flex flex-row justify-center items-center">
              <MdCreditCardOff size={16} className="mr-2" />
              No Payment
            </span>
          </button>
          <button
            onClick={onConfirm}
            className="w-full px-9 py-2.5 bg-[#FACE4F] text-gray-800 text-sm rounded-full font-semibold hover:bg-[#FACE4F] flex flex-row items-center justify-center"
          >
            <span className="mr-2 flex flex-row justify-center items-center">
              <MdPayment size={16} className="mr-2" />
              Take Payment
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActivatePermitModal;
