import React, { useState } from 'react';
import Select from 'react-select';

const DropdownCell = ({
  onStatusChange,
  statusOptions,
  defaultStatus,
  id,
  statusesRequiringConfirmation,
}) => {
  const customStyles = {
    control: styles => ({
      ...styles,
      width: '185px',
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      display: 'flex',
      alignItems: 'center',
      ':before': {
        content: '" "',
        display: 'block',
        width: 10,
        height: 10,
        borderRadius: '50%',
        backgroundColor: data.color,
        marginRight: 10,
      },
    }),
    option: (styles, { data, isFocused }) => ({
      ...styles,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: isFocused ? '#f0f0f0' : null,
      color: '#000',
      ':before': {
        content: '" "',
        display: 'block',
        width: 10,
        height: 10,
        borderRadius: '50%',
        backgroundColor: data.color,
        marginRight: 10,
      },
    }),
  };

  const [selectedOption, setSelectedOption] = useState(
    statusOptions.find(option => option.value === defaultStatus) || null
  );

  const handleChange = option => {
    if (selectedOption.value !== option?.value) {
      if (!statusesRequiringConfirmation?.includes(option?.value)) {
        setSelectedOption(option);
      }
      if (onStatusChange) {
        onStatusChange(id, option.value);
      }
    }
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      options={statusOptions}
      styles={{
        ...customStyles,
        control: (base, state) => ({
          ...base,
          backgroundColor: state.isFocused ? '#ffffff' : '#f9f9f9',
          borderColor: state.isFocused ? selectedOption?.color : '#dcdcdc',
          boxShadow: state.isFocused
            ? `0 0 0 0px ${selectedOption?.color}`
            : 'none',
          cursor: 'pointer',
          paddingRight: '8px',
          '&:hover': {
            backgroundColor: `${selectedOption?.color}1A`,
            borderColor: selectedOption?.color,
          },
        }),
        indicatorSeparator: () => ({ display: 'none' }),
        dropdownIndicator: base => ({
          ...base,
          color: 'black',
          padding: '0px 0px',
          '&:hover': {
            color: 'black',
          },
        }),
      }}
      placeholder="Select Status"
      isSearchable={false}
      menuPortalTarget={document.body}
      menuPosition="fixed"
    />
  );
};

export default DropdownCell;
