import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { getCoordinates } from '../utils/helperFunctions';
import CustomFileUpload from './CustomUploadField';
import { STATES, toastConfig } from '../utils/constants';
import { ClipLoader } from 'react-spinners';
import { gql, useMutation } from '@apollo/client';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';
import { RiDeleteBin6Line } from 'react-icons/ri';

const validationSchema = Yup.object({
  violation: Yup.string().required('Violation is required'),
  officer: Yup.string().required('Officer is required'),
  fineAmount: Yup.number().positive().integer(),
  date: Yup.string(),
  time: Yup.string(),
  location: Yup.string(),
  licensePlate: Yup.string(),
  licensePlateState: Yup.string(),
  color: Yup.string(),
  make: Yup.string(),
  model: Yup.string(),
  description: Yup.string(),
  evidence: Yup.mixed(),
});

const CREATE_CITATION = gql`
  mutation CreateCitation($input: CreateCitationInput!) {
    createCitation(createCitationInput: $input) {
      id
    }
  }
`;
export const UPDATE_CITATION = gql`
  mutation UpdateCitation($input: UpdateCitationInput!) {
    updateCitation(updateCitationInput: $input) {
      id
    }
  }
`;

const AddCitationModal = ({
  isOpen,
  onClose,
  citationDataFromProps,
  refetchCitations,
  setNewCitationId,
  setDeleteCitationData,
  setModalVisible,
}) => {
  const showDeleteButton = !!citationDataFromProps;
  const locationProps = citationDataFromProps?.location?.address
    ? citationDataFromProps?.location?.address
    : citationDataFromProps?.locationResult.name
      ? `${citationDataFromProps?.locationResult.name || ''}, ${citationDataFromProps?.locationResult.postalCode || ''}`
      : '';

  const initialValues = {
    violation: citationDataFromProps?.violationId,
    officer: citationDataFromProps?.userId,
    fineAmount: Number(citationDataFromProps?.fineAmount) || '',
    date: citationDataFromProps?.date || '',
    time: citationDataFromProps?.time || '',
    location: locationProps || '',
    licensePlate: citationDataFromProps?.licensePlateNumber || '',
    licensePlateState: citationDataFromProps?.licensePlateState || '',
    color: citationDataFromProps?.color || '',
    make: citationDataFromProps?.make || '',
    model: citationDataFromProps?.modal || '',
    description: citationDataFromProps?.description || '',
    evidence: '',
  };
  const [createCitation] = useMutation(CREATE_CITATION);
  const [updateCitation] = useMutation(UPDATE_CITATION);
  const { currentUser } = useAuth();
  const officers = currentUser?.municipality?.users;
  const violations = currentUser?.municipality?.violations;
  const submitTicket = async (values, { setSubmitting, resetForm }) => {
    try {
      const res = await getCoordinates(values.location);
      const citationData = {
        userId: values.officer,
        location: { ...res.location, address: values.location },
        locationResult: res.locationResult,
        photo: values?.evidence ? values?.evidence : null,
        description: values.description,
        licensePlateNumber: values.licensePlate,
        licensePlateState: values.licensePlateState,
        make: values.make,
        modal: values.model,
        color: values.color,
        date: values.date ? values.date : null,
        time: values.time ? values.time : null,
        fineAmount: values.fineAmount
          ? JSON.stringify(values.fineAmount)
          : null,
        violationId: values.violation,
      };

      let response;
      if (citationDataFromProps) {
        response = await updateCitation({
          variables: {
            input: {
              ...citationData,
              municipalityId: currentUser?.municipalityId,
              id: citationDataFromProps?.id,
            },
          },
        });
      } else {
        response = await createCitation({
          variables: {
            input: {
              ...citationData,
              municipalityId: currentUser?.municipalityId,
            },
          },
        });
      }
      setNewCitationId(response?.data?.createCitation?.id);

      if (response.data) {
        refetchCitations();
        onClose();
        resetForm();
        toast.success(
          citationDataFromProps
            ? 'Citation updated successfully'
            : 'Citation added successfully',
          toastConfig
        );
      }
    } catch (error) {
      console.error('Error adding citation:', error);
      toast.error(
        citationDataFromProps
          ? 'Error updating citation'
          : 'Error adding citation',
        toastConfig
      );
    } finally {
      setSubmitting(false);
    }
  };
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    submitTicket(values, { setSubmitting, resetForm });
  };
  if (!isOpen) return null;

  return (
    <div
      onClick={onClose}
      className="fixed inset-0 z-50 flex items-center justify-center bg-[rgba(0,0,0,0.5)] bg-opacity-75"
    >
      <div
        onClick={e => e.stopPropagation()}
        className="w-[636px] bg-[#EDEDED] rounded-md shadow-lg p-9"
      >
        <h2 className="text-lg font-bold text-center mb-9">Citation</h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, touched, errors, setFieldValue }) => (
            <Form>
              <div className="flex flex-row items-center flex-1">
                <div className="flex flex-1 flex-col mr-2">
                  <Field
                    as="select"
                    className={`${touched.violation && errors.violation ? 'border-red-500' : ''} w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                    name="violation"
                    onChange={e => {
                      const selectedViolationId = e.target.value;
                      const selectedViolation = violations.find(
                        v => v.id === selectedViolationId
                      );
                      setFieldValue('violation', selectedViolationId);
                      if (selectedViolation) {
                        setFieldValue(
                          'fineAmount',
                          Number(selectedViolation.fineAmount)
                        );
                      }
                    }}
                  >
                    <option value={null}>Select a violation</option>
                    {violations.map((violation, index) => (
                      <option key={index} value={violation.id}>
                        {violation.title}
                      </option>
                    ))}
                  </Field>
                  <div className="h-5">
                    <ErrorMessage
                      name="violation"
                      component="div"
                      className="text-red-500 text-xs "
                    />
                  </div>
                  {values.violation && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      Violation
                    </label>
                  )}
                </div>
                <div className="flex flex-1 flex-col">
                  <Field
                    as="select"
                    className={`${touched.violation && errors.violation ? 'border-red-500' : ''} w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white border-[rgba(0,0,0,0.1)]`}
                    name="officer"
                  >
                    <option value="">Select an officer</option>
                    {officers.map(officer => (
                      <option key={officer.id} value={officer.id}>
                        {officer.name}
                      </option>
                    ))}
                  </Field>
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs "
                      name="officer"
                      component="div"
                    />
                  </div>
                  {values.officer && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      Officer
                    </label>
                  )}
                </div>
              </div>
              <div className="flex flex-row items-center flex-1 ">
                <div className="flex flex-[2] flex-col mr-2">
                  <div className="relative w-full">
                    <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                      <span className="text-gray-500 text-sm">$</span>
                    </div>
                    <Field
                      value={values.fineAmount}
                      name="fineAmount"
                      type="number"
                      placeholder="Fine Amount"
                      className="w-full pl-[26px] focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white border-[rgba(0,0,0,0.1)]"
                    />
                  </div>
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs mt-1"
                      name="fineAmount"
                      component="div"
                    />
                  </div>
                  {values.fineAmount && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      Fine Amount
                    </label>
                  )}
                </div>
                <div className="flex flex-1 flex-col mr-2">
                  <Field
                    name="date"
                    type="date"
                    placeholder="DD/MM/YYYY"
                    className={`w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                  />
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs "
                      name="date"
                      component="div"
                    />
                  </div>
                  {values.date && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      Date
                    </label>
                  )}
                </div>
                <div className="flex flex-col flex-1">
                  <Field
                    type="time"
                    name="time"
                    placeholder="00:00 AM"
                    className={`w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                  />
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs "
                      name="time"
                      component="div"
                    />
                  </div>
                  {values.time && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      Time
                    </label>
                  )}
                </div>
              </div>
              <div className="flex flex-1 flex-col ">
                <Field
                  className={`w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                  name="location"
                  type="text"
                  placeholder="Address of Violation"
                />
                {values.location && (
                  <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                    Address of Violation
                  </label>
                )}
                <div className="h-5">
                  <ErrorMessage
                    className="text-red-500 text-xs "
                    name="location"
                    component="div"
                  />
                </div>
              </div>
              <div className="flex flex-row flex-1 items-center ">
                <div className="flex flex-1 flex-col mr-2">
                  <Field
                    id="licensePlate"
                    style={{ textTransform: 'uppercase' }}
                    name="licensePlate"
                    type="text"
                    placeholder=""
                    className={`place w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                  />
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs "
                      name="licensePlate"
                      component="div"
                    />
                  </div>
                  {!values.licensePlate && (
                    <label
                      htmlFor="licensePlate"
                      className="cursor-text absolute text-sm ml-4 text-gray-500 mt-[15px] "
                    >
                      License Plate
                    </label>
                  )}
                  {values.licensePlate && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      License Plate
                    </label>
                  )}
                </div>

                <div className="flex flex-1 flex-col">
                  <Field
                    name="licensePlateState"
                    as="select"
                    placeholder="State"
                    className={`w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                  >
                    <option value={null}>Select a state</option>
                    {STATES.map((state, index) => (
                      <option key={index} value={state.value}>
                        {state.label}
                      </option>
                    ))}
                  </Field>
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs "
                      name="licensePlateState"
                      component="div"
                    />
                  </div>
                  {values.licensePlateState && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      State
                    </label>
                  )}
                </div>
              </div>
              <div className="flex flex-1 flex-row items-center w ">
                <div className="flex flex-1 mr-2 flex-col">
                  <Field
                    name="color"
                    type="text"
                    placeholder="Color"
                    className={`w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                  />
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs "
                      name="color"
                      component="div"
                    />
                  </div>
                  {values.color && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      Color
                    </label>
                  )}
                </div>
                <div className="flex flex-1 mr-2 flex-col">
                  <Field
                    name="make"
                    type="text"
                    placeholder="Make"
                    className={`w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                  />
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs "
                      name="make"
                      component="div"
                    />
                  </div>
                  {values.make && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      Make
                    </label>
                  )}
                </div>
                <div className="flex flex-1 flex-col">
                  <Field
                    name="model"
                    type="text"
                    placeholder="Model"
                    className={`w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                  />
                  <div className="h-5">
                    <ErrorMessage
                      className="text-red-500 text-xs "
                      name="model"
                      component="div"
                    />
                  </div>
                  {values.model && (
                    <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                      Model
                    </label>
                  )}
                </div>
              </div>

              <div className=" flex-col flex flex-1">
                <Field
                  name="description"
                  type="text"
                  as="textarea"
                  placeholder="Description"
                  className={`pt-3 h-[110px] w-full focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent rounded-md px-4 border border-1 text-sm bg-white  border-[rgba(0,0,0,0.1)]`}
                />
                <div className="h-5">
                  <ErrorMessage
                    className="text-red-500 text-xs mt-[-6px]"
                    name="description"
                    component="div"
                  />
                </div>
                {values.description && (
                  <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                    Description
                  </label>
                )}
              </div>
              <CustomFileUpload />
              <div className="h-[1px] bg-[rgba(0,0,0,0.1)] my-9" />
              <div className="flex flex-1 justify-between items-center">
                {showDeleteButton ? (
                  <button
                    className="flex flex-row items-center px-9 py-[10px] text-sm font-semibold rounded-full mr-4 bg-[rgba(0,0,0,0.05)] hover:bg-[rgba(0,0,0,0.15)]"
                    type="button"
                    onClick={() => {
                      onClose();
                      setDeleteCitationData(citationDataFromProps);
                      setModalVisible(true);
                    }}
                  >
                    <RiDeleteBin6Line className="w-4 h-4 mr-3" />
                    Delete
                  </button>
                ) : (
                  <div />
                )}
                <div className="flex flex-row ">
                  <button
                    className="px-9 py-[10px] text-sm font-semibold rounded-full mr-4 bg-[rgba(0,0,0,0.05)] hover:bg-[rgba(0,0,0,0.15)]"
                    type="submit"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    className="px-9 py-[10px] text-sm font-semibold rounded-full bg-[#FACE4F] hover:bg-[#D9B345] flex items-center"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <>
                        <ClipLoader
                          size={18}
                          color={'#000000'}
                          className="mr-2"
                        />
                        Submitting...
                      </>
                    ) : (
                      'Submit'
                    )}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddCitationModal;
