import React, { createContext, useContext, useState, useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';

const ME_QUERY = gql`
  query Me {
    me {
      id
      municipalityId
      municipality {
        organizationType
        citationLayout
        customerTypes {
          id
          customerType
        }
        maxPermit
        users {
          id
          name
          email
          profileImage
          role
          active
          municipalityId
          createdAt
          updatedAt
        }
        violations {
          id
          code
          title
          fineAmount
        }
        parkingPermits {
          id
          zone
          sixMonthFee
          oneYearFee
          twoYearFee
        }
        parkingZones {
          id
          zone
          numberOfSpaces
          maxCostPerDay
          costPerHour
        }
        enableParkingPermits
        enableCitations
      }
      role
    }
  }
`;

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fetchMe, { data, error }] = useLazyQuery(ME_QUERY);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      fetchMe();
    } else {
      setLoading(false);
    }
  }, [fetchMe]);

  useEffect(() => {
    if (data) {
      setCurrentUser(data.me);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      console.error('Error fetching user data:', error);
      setLoading(false);
    }
  }, [error]);

  const value = {
    currentUser,
    accessToken: localStorage.getItem('accessToken'),
    setCurrentUser,
    fetchMe,
    organizationType: currentUser?.municipality?.organizationType,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading ? children : <div>Loading...</div>}
    </AuthContext.Provider>
  );
};
