import { useState } from 'react';

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  description,
  subDescription,
  buttonTitle,
  loading,
  icon,
  showRefundInput,
}) => {
  const [refundAmount, setRefundAmount] = useState();
  if (!isOpen) return null;
  const handleClose = () => {
    setRefundAmount(null);
    onClose();
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-[rgba(0,0,0,0.5)] bg-opacity-75"
      onClick={handleClose}
    >
      <div
        onClick={e => e.stopPropagation()}
        className="bg-white rounded-md shadow-lg p-9 w-1/3"
      >
        <h2 className={`text-lg font-bold text-center mb-9`}>{title}</h2>
        <p
          className={`text-sm text-[rgba(0,0,0,0.5)] ${showRefundInput ? 'text-left' : 'text-center'}`}
        >
          {description}
        </p>
        <p
          className={`text-sm text-[rgba(0,0,0,0.5)] ${showRefundInput ? 'text-left' : 'text-center'}`}
        >
          {subDescription ||
            'It will be permanently deleted, and it can not be restored.'}
        </p>
        {showRefundInput && (
          <div className="flex flex-[2] flex-col mt-6">
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                <span className="text-gray-500 text-sm">$</span>
              </div>
              <input
                value={refundAmount}
                onChange={e => setRefundAmount(e.target.value)}
                name="fineAmount"
                type="number"
                placeholder="Refund Amount (optional)"
                className="w-full pl-[26px] focus:outline-none focus:ring-1 focus:ring-[rgba(0,0,0,0.2)] focus:border-transparent h-[50px] rounded-md px-4 border border-1 text-sm bg-white border-[rgba(0,0,0,0.1)]"
              />
            </div>
            {!!refundAmount && (
              <label className="absolute text-[11px] ml-4 text-[rgba(0,0,0,0.5)]">
                Refund Amount (optional)
              </label>
            )}
          </div>
        )}
        <div className="h-[1px] bg-[rgba(0,0,0,0.1)] my-9" />
        <div className="flex justify-center space-x-4">
          <button
            onClick={handleClose}
            className="w-full py-2 bg-[rgba(0,0,0,0.05)] text-gray-800 font-semibold rounded-full hover:bg-[rgba(0,0,0,0.15)]"
          >
            Cancel
          </button>
          <button
            onClick={() => onConfirm(refundAmount)}
            className="w-full py-2 bg-[#F75474] text-white rounded-full font-semibold hover:bg-[#DB4865] flex flex-row items-center justify-center"
          >
            <span className="mr-2 flex flex-row justify-center items-center">
              {icon}
              {loading
                ? buttonTitle === 'Delete'
                  ? 'Deleting...'
                  : 'Expiring...'
                : buttonTitle || 'Delete'}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
