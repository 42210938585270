import axios from 'axios';
import { REACT_APP_GOOGLE_MAPS_API_KEY } from './constants';

export const encodeEmail = email => {
  // Define a function to encode a character
  function encodeChar(char) {
    switch (char) {
      case '.':
        return '%2E';
      case '_':
        return '%5F';
      case '-':
        return '%2D';
      case '@':
        return '%40';
      default:
        return encodeURIComponent(char);
    }
  }

  // Encode each character in the email address
  const encodedEmail = Array.from(email, encodeChar).join('');

  return encodedEmail;
};
export const formatDateTime = (date, time) => {
  if (date && time) {
    const dateTimeString = `${date}T${time}`;
    const dateTime = new Date(dateTimeString);

    return new Intl.DateTimeFormat('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }).format(dateTime);
  } else {
    return null;
  }
};

export const formatStatus = status => {
  if (!status) return '';
  return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
};

export const calculateExpirationDate = (issueDate, term) => {
  if (!issueDate || !term) {
    return null;
  }

  const issueDateObj = new Date(issueDate);

  if (isNaN(issueDateObj.getTime())) {
    return null;
  }

  let expirationDateObj = new Date(issueDateObj);

  switch (term) {
    case 'sixMonth':
      expirationDateObj.setMonth(expirationDateObj.getMonth() + 6);
      break;
    case 'oneYear':
      expirationDateObj.setFullYear(expirationDateObj.getFullYear() + 1);
      break;
    case 'twoYear':
      expirationDateObj.setFullYear(expirationDateObj.getFullYear() + 2);
      break;
    default:
      return null;
  }

  const year = expirationDateObj.getFullYear();
  const month = (expirationDateObj.getMonth() + 1).toString().padStart(2, '0');
  const day = expirationDateObj.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const getCoordinates = async address => {
  const apiKey = REACT_APP_GOOGLE_MAPS_API_KEY;
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`;

  try {
    const response = await axios.get(url);
    const { results } = response.data;

    if (results.length > 0) {
      const { lat, lng } = results[0].geometry.location;

      // Mocked location data for demonstration purposes
      const location = {
        accuracy: 4.7476372661172555,
        altitude: 213.39821704570204,
        altitudeAccuracy: 3.3592376251410836,
        heading: -1,
        latitude: lat,
        longitude: lng,
        speed: 0.06773823106788453,
      };

      // Extract region details from address components
      const addressComponents = results[0].address_components;
      let country = '',
        isoCountryCode = '',
        name = '',
        region = '',
        timezone = 'America/New_York';

      addressComponents.forEach(component => {
        if (component.types.includes('country')) {
          country = component.long_name;
          isoCountryCode = component.short_name;
        } else if (component.types.includes('administrative_area_level_1')) {
          name = component.long_name;
          region = component.long_name;
        }
      });

      const regionDetails = {
        country,
        isoCountryCode,
        name,
        region,
        timezone,
      };

      return { location, locationResult: regionDetails };
    } else {
      throw new Error('No results found');
    }
  } catch (error) {
    console.error('Error fetching coordinates:', error);
    return {
      location: {
        accuracy: 4.7476372661172555,
        altitude: 213.39821704570204,
        altitudeAccuracy: 3.3592376251410836,
        heading: -1,
        latitude: 40.7128,
        longitude: 74.006,
        speed: 0.06773823106788453,
      },
      locationResult: {
        country: '',
        isoCountryCode: '',
        name: '',
        region: '',
        timezone: '',
      },
    };
  }
};

export const formatTimestamp = timeStamp => {
  const [datePart, timePart] = timeStamp?.split(' ');
  const [year, month, day] = datePart?.split(':')?.map(Number);
  const [hour, minute, second] = timePart.split(':')?.map(Number);

  // Format the date as DD/MM/YYYY
  const date = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

  // Convert 24-hour time to 12-hour time
  const period = hour >= 12 ? 'PM' : 'AM';
  const adjustedHour = hour % 12 || 12;
  const time = `${adjustedHour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${period}`;

  return { date, time };
};

export const getChangedLabel = (organiztionType, capitalized, plural) => {
  const types = ['HOA', 'PRIVATE_LOT'];
  let label;

  if (types.includes(organiztionType)) {
    label = capitalized ? 'Parking Pass' : 'parking pass';
  } else {
    label = capitalized ? 'Permit' : 'permit';
  }

  // Add plural logic with special handling for "Parking Pass"
  if (plural) {
    if (label.toLowerCase().endsWith('s')) {
      label += 'es'; // Add 'es' for labels ending with 's'
    } else {
      label += 's'; // Default pluralization
    }
  }

  return label;
};
