import { Bounce } from 'react-toastify';

export const REACT_APP_GOOGLE_MAPS_API_KEY =
  'AIzaSyD7XplYDPbpjq1JE2qBUJZ6kb4QbHU8fMc';

export const tableTheme = {
  root: {
    base: 'w-full text-left text-sm text-gray-500 dark:text-gray-400 border border-[1px] border-[rbga(0,0,0,0.02)] ',
    shadow: '',
    wrapper: 'relative',
  },
  body: {
    base: 'group/body',
    cell: {
      base: 'px-2 py-3',
    },
  },
  head: {
    base: 'group/head text-sm text-gray-700 dark:text-gray-400',
    cell: {
      base: 'bg-[rgba(0,0,0,0.02)] px-3 py-2 dark:bg-gray-700',
    },
  },
  row: {
    base: 'group/row',
    hovered: 'hover:bg-gray-50 dark:hover:bg-gray-600',
    striped:
      'odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700',
  },
};
export const paginationTheme = {
  base: '',
  layout: {
    table: {
      base: 'text-sm text-gray-700 dark:text-gray-400',
      span: 'font-semibold text-gray-900 dark:text-white',
    },
  },
  pages: {
    base: 'xs:mt-0 mt-2 inline-flex items-center -space-x-px',
    showIcon: 'inline-flex',
    previous: {
      base: 'ml-0 rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white',
      icon: 'h-5 w-5',
    },
    next: {
      base: 'rounded-r-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white',
      icon: 'h-5 w-5',
    },
    selector: {
      base: 'w-12 border border-gray-300 bg-white py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white',
      active:
        'bg-[rgba(0,0,0,0.1)] text-black hover:bg-cyan-100 hover:text-black dark:border-gray-700 dark:bg-gray-700 dark:text-white',
      disabled: 'cursor-not-allowed opacity-50',
    },
  },
};

export const TERMS = [
  { key: 'sixMonth', label: '6 months', value: 'sixMonth' },
  { key: 'oneYear', label: '1 year', value: 'oneYear' },
  { key: 'twoYear', label: '2 years', value: 'twoYear' },
];

export const STATES = [
  { key: 'Alabama', label: 'Alabama', value: 'AL' },
  { key: 'Alaska', label: 'Alaska', value: 'AK' },
  { key: 'American Samoa', label: 'American Samoa', value: 'AS' },
  { key: 'Arizona', label: 'Arizona', value: 'AZ' },
  { key: 'Arkansas', label: 'Arkansas', value: 'AR' },
  { key: 'California', label: 'California', value: 'CA' },
  { key: 'Colorado', label: 'Colorado', value: 'CO' },
  { key: 'Connecticut', label: 'Connecticut', value: 'CT' },
  { key: 'Delaware', label: 'Delaware', value: 'DE' },
  {
    key: 'District Of Columbia',
    label: 'District Of Columbia',
    value: 'DC',
  },
  {
    key: 'Federated States Of Micronesia',
    label: 'Federated States Of Micronesia',
    value: 'FM',
  },
  { key: 'Florida', label: 'Florida', value: 'FL' },
  { key: 'Georgia', label: 'Georgia', value: 'GA' },
  { key: 'Guam Gu', label: 'Guam Gu', value: 'GU' },
  { key: 'Hawaii', label: 'Hawaii', value: 'HI' },
  { key: 'Idaho', label: 'Idaho', value: 'ID' },
  { key: 'Illinois', label: 'Illinois', value: 'IL' },
  { key: 'Indiana', label: 'Indiana', value: 'IN' },
  { key: 'Iowa', label: 'Iowa', value: 'IA' },
  { key: 'Kansas', label: 'Kansas', value: 'KS' },
  { key: 'Kentucky', label: 'Kentucky', value: 'KY' },
  { key: 'Louisiana', label: 'Louisiana', value: 'LA' },
  { key: 'Maine', label: 'Maine', value: 'ME' },
  {
    key: 'Marshall Islands',
    label: 'Marshall Islands',
    value: 'MH',
  },
  { key: 'Maryland', label: 'Maryland', value: 'MD' },
  { key: 'Massachusetts', label: 'Massachusetts', value: 'MA' },
  { key: 'Michigan', label: 'Michigan', value: 'MI' },
  { key: 'Minnesota', label: 'Minnesota', value: 'MN' },
  { key: 'Mississippi', label: 'Mississippi', value: 'MS' },
  { key: 'Missouri', label: 'Missouri', value: 'MO' },
  { key: 'Montana', label: 'Montana', value: 'MT' },
  { key: 'Nebraska', label: 'Nebraska', value: 'NE' },
  { key: 'Nevada', label: 'Nevada', value: 'NV' },
  { key: 'New Hampshire', label: 'New Hampshire', value: 'NH' },
  { key: 'New Jersey', label: 'New Jersey', value: 'NJ' },
  { key: 'New Mexico', label: 'New Mexico', value: 'NM' },
  { key: 'New York', label: 'New York', value: 'NY' },
  { key: 'North Carolina', label: 'North Carolina', value: 'NC' },
  { key: 'North Dakota', label: 'North Dakota', value: 'ND' },
  {
    key: 'Northern Mariana Islands',
    label: 'Northern Mariana Islands',
    value: 'MP',
  },
  { key: 'Ohio', label: 'Ohio', value: 'OH' },
  { key: 'Oklahoma', label: 'Oklahoma', value: 'OK' },
  { key: 'Oregon', label: 'Oregon', value: 'OR' },
  { key: 'Palau', label: 'Palau', value: 'PW' },
  { key: 'Pennsylvania', label: 'Pennsylvania', value: 'PA' },
  { key: 'Puerto Rico', label: 'Puerto Rico', value: 'PR' },
  { key: 'Rhode Island', label: 'Rhode Island', value: 'RI' },
  { key: 'South Carolina', label: 'South Carolina', value: 'SC' },
  { key: 'South Dakota', label: 'South Dakota', value: 'SD' },
  { key: 'Tennessee', label: 'Tennessee', value: 'TN' },
  { key: 'Texas', label: 'Texas', value: 'TX' },
  { key: 'Utah', label: 'Utah', value: 'UT' },
  { key: 'Vermont', label: 'Vermont', value: 'VT' },
  { key: 'Virgin Islands', label: 'Virgin Islands', value: 'VI' },
  { key: 'Virginia', label: 'Virginia', value: 'VA' },
  { key: 'Washington', label: 'Washington', value: 'WA' },
  { key: 'West Virginia', label: 'West Virginia', value: 'WV' },
  { key: 'Wisconsin', label: 'Wisconsin', value: 'WI' },
  { key: 'Wyoming', label: 'Wyoming', value: 'WY' },
];

export const toastConfig = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  theme: 'light',
  transition: Bounce,
};

export const citationStatusOptions = [
  { value: 'PAYMENT_DUE', label: 'Payment Due', color: '#FACE4F' }, // Red
  { value: 'PAID', label: 'Paid', color: '#47B403' }, // Green
  { value: 'APPEAL_PENDING', label: 'Appeal Pending', color: '#008CD2' }, // Orange
  { value: 'NSF_CHECK_HOLD', label: 'NSF Check Hold', color: '#DD5353' }, // Purple
  { value: 'DMV_HOLD', label: 'DMV Hold', color: '#DD5353' }, // Red-Orange
  { value: 'HEARING_SCHEDULED', label: 'Hearing Scheduled', color: '#8E70C0' }, // Blue
  { value: 'COLLECTIONS', label: 'Collections', color: '#DD5353' }, // Gold
  { value: 'UNCOLLECTIBLE', label: 'Uncollectible', color: '#666666' }, // Grey
  { value: 'APPEAL_UPHELD', label: 'Appeal Upheld', color: '#FACE4F' }, // Teal
];
export const permitStatusOptions = [
  { value: 'IN_PRODUCTION', label: 'In Production', color: '#008CD2' },
  { value: 'ACTIVE', label: 'Active', color: '#47B403' },
  { value: 'EXPIRED', label: 'Expired', color: '#DD5353' },
  { value: 'PENDING_PAYMENT', label: 'Pending Payment', color: '#FACE4F' },
  { value: 'LOST', label: 'Lost', color: '#666666' },
  { value: 'STOLEN', label: 'Stolen', color: '#666666' },
  { value: 'RETURNED', label: 'Returned', color: '#666666' },
  { value: 'TEMPORARY', label: 'Temporary', color: '#8E70C0' },
];
// export enum PermitStatus {
//   IN_PRODUCTION = 'in_production',
//   EXPIRED = 'expired',
//   ACTIVE = 'active',
//   PENDING_PAYMENT = 'pending_payment',
//   LOST = 'lost',
//   STOLEN = 'stolen',
//   TEMPORARY = 'temporary',
// }
